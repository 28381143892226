<template>
  <div>
    <TailwindComponentsCommandPalette
      :items="permittedCollections(filteredTagsColl)"
      :initialSelected="presetColls"
      :icon="'filter'"
      :selectable="true"
      :customList="true"
      :isLoading="isLoading"
      :placeholder="placeholder"
      :label="label"
      :helpText="helpText"
      @search="getFilteredTags"
      @selected="optionSelected"
      :emitSelection="emitSelection"
    >
      <template v-slot:selected-item="{ selectedItem }">
        <div class="pr-6">
          {{ selectedItem.colName }}
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div class="app-command-palette__content">
          <div class="app-command-palette__content__text">
            {{ item.colName }}
          </div>
        </div>
      </template>
    </TailwindComponentsCommandPalette>
  </div>
</template>

<script>
export default {
  setup() {
    const collectionsApi = useCollections()
    const { userProfile } = useKeycloak()
    return {
      collectionsApi,
      userProfile,
    }
  },
  data() {
    return {
      tagsColl: [],
      collectionData: [],
      filteredTagsColl: [],
      selectedColl: null,
      awaitingSearch: false,
      prevText: '',
      timeout: null,
      listIcon: 'folder-multiple-outline',
      isLoading: false,
      presetColls: [],
    }
  },
  props: {
    restrict: {
      type: Boolean,
      default: false,
    },
    initColls: {
      type: Array,
      default: false,
    },
    helpText: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Search collections by title/ID',
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: '',
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    collId: {
      type: Number,
    },
    newDesign: {
      type: Boolean,
      default: false,
    },
    emitSelection: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedColl: {
      handler(value) {
        this.$emit('emitColl', value)
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.initColls) {
      await this.loadCollections()
    }
    await this.load()
  },
  methods: {
    permittedCollections(colls) {
      if (this.userProfile.IsAdmin || !this.restrict) {
        return colls
      } else {
        return colls.filter(
          collection =>
            collection.role === ROLE_OWNER || collection.role === ROLE_EDITOR
        )
      }
    },
    optionSelected(selected) {
      this.selectedColl = null // set to null to trigger the watcher
      this.selectedColl = selected
    },

    async getFilteredTags(text) {
      clearTimeout(this.timeout)

      var self = this
      this.timeout = setTimeout(function () {
        self.getFilteredCollection(text)
      }, 500)
    },
    async getFilteredCollection(text) {
      this.isLoading = true
      let data
      try {
        data = await this.collectionsApi.filterCollections({
          offset: 0,
          limit: 100,
          filter: text,
        })
        this.collectionData = data.trpCollection.reverse()

        this.filteredTagsColl = this.collectionData
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        return
      }
    },
    async loadCollections() {
      this.isLoading = true
      let data
      try {
        for (let index in this.initColls) {
          let collId = this.initColls[index]

          data = await this.collectionsApi.fetchCollectionMeta({
            collId: collId,
          })

          this.presetColls.push(data)
        }
      } catch (err) {
        this.isLoading = false
        return
      }
    },
    async load() {
      this.isLoading = true

      let data
      try {
        data = await this.collectionsApi.fetchCollections({
          offset: 0,
          limit: 100,
        })
        this.collectionData = data.trpCollection.reverse()
        this.filteredTagsColl = this.collectionData
        if (this.preSetCollection) {
          this.selectedColl = this.collectionData[0]
          // this.collId = this.collectionData[0].colId
          this.tagsColl.push(this.collectionData[0])
        }
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        return
      }
    },
    emitRemoved() {
      // this.load()
      this.$emit('removedTag', 'wasRemoved')
      this.$router.replace({ query: { search: null } })
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
  },
}
</script>

<style scoped>
.box {
  padding: 0.25rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
