<template>
  <div class="app-modal__box">
    <div class="app-modal__header">
      <h3 class="app-title">{{ $t('Delete Sites instance') }}</h3>
    </div>
    <div class="app-modal__body">
      <div>
        <span class="text-primary mr-1 inline-block font-bold"
          >{{ item?.colName }}
        </span>
        {{
          $t(
            'Are you sure you want to delete this Sites instance? This action cannot be undone.'
          )
        }}
      </div>
    </div>
    <div class="app-modal__footer">
      <BaseButton
        :loading="isLoading"
        :type="'danger'"
        :symbol="'delete'"
        :label="'Delete'"
        @click="deleteCollection"
      ></BaseButton>
    </div>
  </div>
</template>

<script>
const sitesApi = useSites()
const subscriptionsApi = useSubscriptions()
const { $bus } = useNuxtApp()

export default {
  name: 'ModalDeleteCollection',
  props: {
    item: Object,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async deleteCollection() {
      this.isLoading = true

      try {
        if (this.item.status !== 'Draft') {
          await subscriptionsApi.setSitesSubscriptionUrl({
            subscriptionId: this.item.subscriptionId,
            url: null,
          })
        }
        let data = await sitesApi.deleteSitesCollection({
          customUrl: this.item.url,
        })
        this.$bus.emit('notification', {
          message: this.$t('Deleted Sites instance ') + this.item.title,
          type: 'success',
        })
        this.$bus.emit('loadRS')
      } catch (err) {
        this.$bus.emit('notification', {
          message: this.$t('Delete Sites instance failed'),
          type: 'error',
        })
        this.$sentry.captureException(err)
      }
      // Close Modal
      this.$bus.emit('close-modal')
      this.isLoading = false
    },
  },
}
</script>
